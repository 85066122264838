import React from "react"
import { graphql, Link } from "gatsby"
import {
  generatePath,
  generateNameWithHonorific,
  editionIsReleased,
} from "../lib/helpers"
import WebsiteMeta from "../components/WebsiteMeta"
import PortableTextRenderer from "../components/PortableTextRenderer"
import ListElement from "../components/ListElement"
import FadeIn from "../components/FadeIn"
import ContactForm from "../components/ContactForm"
import styled from "styled-components"

export default function Home({ data }) {
  const { content, editions } = data
  const releasedEditions = editions.edges.filter(({ node }) => {
    return editionIsReleased(node.release, node.status)
  })

  return (
    <>
      <WebsiteMeta titleFull="The Priory Press" />

      <CoverPhoto>
        <PortableTextRenderer text={content._rawCoverPhoto} />
      </CoverPhoto>

      <FadeIn direction="up">
        <AboutSection id="about">
          <PortableTextRenderer text={content._rawBody} />
        </AboutSection>
      </FadeIn>

      {content && content.team && content.team.length > 0 && (
        <FadeIn direction="up" delay={250}>
          <MeetSection>
            <h2>Meet Our Team</h2>

            <p>Our current editorial team consists of:</p>

            <People>
              {content.team.map(node => (
                <Link
                  to={generatePath(node.person.slug.current, "person")}
                  className="custom"
                  key={node.person._id}
                >
                  <Person>
                    <h3>
                      {generateNameWithHonorific(
                        node.person.name,
                        node.person.honorific
                      )}
                    </h3>
                    <p>{node.role}</p>
                  </Person>
                </Link>
              ))}

              {(content.team.length + 1) % 3 === 0 && (
                <Link to={generatePath("/contact", "page")} className="custom">
                  <Person className="featured">
                    <h3>
                      Contribute to a Future Edition <span>&rarr;</span>
                    </h3>
                  </Person>
                </Link>
              )}
            </People>
          </MeetSection>
        </FadeIn>
      )}

      {releasedEditions.length > 0 && (
        <FadeIn direction="up" delay={350}>
          <EditionSection>
            <h2>Most Recent Publication</h2>

            <Editions>
              {releasedEditions.slice(0, 1).map(({ node }, index) => (
                <ListElement
                  link={generatePath(node.slug.current, "edition")}
                  title={node.name}
                  extras1={"Released "}
                  line2={node.formattedRelease}
                  index={index + 1 < 10 ? `0${index + 1}` : index + 1}
                  key={node._id}
                />
              ))}
            </Editions>
          </EditionSection>
        </FadeIn>
      )}

      <FadeIn direction="up" delay={450}>
        <ContactSection id="contact">
          <FormWrapper>
            <ContactForm
              title="Reach Out to Our Team"
              subtitle="If you are interested in contributing to a future edition of the Priory Press or have any feedback, questions, or concerns, please let us know here."
              recipientname="Priory Press Team"
              recipientemail="publab"
            />
          </FormWrapper>
        </ContactSection>
      </FadeIn>
    </>
  )
}

export const pageQuery = graphql`
  {
    content: sanityLanding(_id: { eq: "landing-page" }) {
      _rawCoverPhoto(resolveReferences: { maxDepth: 10 })
      _rawBody(resolveReferences: { maxDepth: 10 })
      team {
        person {
          _id
          honorific
          name
          slug {
            current
          }
        }
        role
      }
    }

    editions: allSanityEdition(sort: { fields: release, order: DESC }) {
      edges {
        node {
          _id
          name
          release
          formattedRelease: release(formatString: "MMMM D, YYYY")
          slug {
            current
          }
          status
        }
      }
    }
  }
`

const CoverPhoto = styled.div`
  figure {
    margin-top: 0;
  }
`

const Editions = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 1.5rem;
`

const AboutSection = styled.section`
  margin-bottom: 3rem;

  h1 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.5;
    font-size: 1.1em;
  }
`

const MeetSection = styled.section`
  margin-bottom: 3rem;

  h2 {
    margin-bottom: 0.5rem;
  }

  > p {
    line-height: 1.4;
  }
`

const People = styled.div`
  display: block;
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  > a.custom {
    transition: background-color 0.15s ease;
    border-radius: 10px;

    &:hover {
      background-color: var(--mid-white);
    }
  }

  @media only screen and (min-width: 767px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const Person = styled.div`
  --padding: 0.75em;
  padding: var(--padding);
  border-radius: 10px;
  height: calc(100% - var(--padding) - 1rem);

  &:not(.featured) {
    border: 2px solid var(--mid-white);
  }

  &.featured {
    background-color: var(--maroon);
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: rgba(var(--maroon-rgb), 0.85);
    }

    a.custom & span {
      display: inline-block;
      margin-left: 0.325rem;
      will-change: transform;
      transform: translateX(0);
      transition: transform 0.375s ease;
    }

    a.custom:hover & span,
    a.custom:focus-visible & span {
      transform: translateX(0.35rem);
      transition: transform 0.25s ease-out;
    }

    h3 {
      color: var(--page-background);
    }
  }

  a:focus-visible & {
    border-color: var(--gold);
    outline: none;
  }

  h3 {
    margin-bottom: 0.25em;
  }

  p {
    font-size: 0.9em;
    opacity: 0.75;
  }
`

const EditionSection = styled.section`
  margin-bottom: 3rem;

  h2 {
    margin-bottom: 0.5rem;
  }
`

const ContactSection = styled.section`
  margin-bottom: 3rem;

  > p {
    line-height: 1.4;
  }
`

const FormWrapper = styled.div`
  margin-top: 2rem;
`
